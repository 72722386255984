import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import DeliveryDetails from "../../components/Epharmacy/SpecialOrder/DeliveryDetails"

export default ({ location }) => (
  <Layout
    title="Delivery Details"
    subtitle={
      <span>
        Please answer the following questions accurately.
        <br className="is-hidden-mobile" /> Your information will be used as a
        basis for your delivery.
      </span>
    }
    process="epharmacy"
    seoTitle="Delivery Details"
    display={{ footer: false, helpCenterBanner: false }}
    path={location?.pathname}
    underMaintenance={
      process.env.GATSBY_HAS_SPECIAL_ORDER_ENABLED === "disabled"
    }
  >
    <Container isCentered desktop={6} fullhd={6}>
      <DeliveryDetails
        nextRoute={"/special-order/shipping-and-payment"}
        backRoute={"/special-order"}
      />
    </Container>
  </Layout>
)
